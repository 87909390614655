@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html{
  height: 100vh; /* set viewport constraint */
  min-height: 100%; /* enforce height */
}


body{

margin: 0;
padding: 0;


/* Grab the image from Unsplash... */
background-image: url('https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80');
/* Center the image vertically and horizontally */
background-position: center center;

/* Let's not tile it! */
background-repeat: no-repeat;

/* Keep it fixed to stop it misbehaving...scoll content over the top */
background-attachment: fixed;

/* Ensure */
background-size: cover;

/* Set a background color that will be displayed
while the background image is loading */
background-color: #ffffff;

-webkit-background-size: cover; /* safari may need this */
}




.text-shadow {
  text-shadow: 0 2px 4px rgba(0,0,0,0.10);
}

.text-shadow-md {
  text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
}

.text-shadow-lg {
  text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
}

.text-shadow-none {
  text-shadow: none;
}



.text-shadow-white {
  text-shadow: 1px 1px 1px white;
}

.text-shadow-white-md {
  text-shadow: 4px 4px 8px rgba(255,255,255,0.82), 0 2px 4px rgba(255,255,255,1);
}

.text-shadow-white-lg {
  text-shadow: 15px 15px 30px rgba(255,255,255,0.8), 0 5px 15px rgba(255,255,255,1);
}

.text-shadow-white-none {
  text-shadow: none;
}

.portfolio-img{
  width: 150px;
  height: auto;
  background-color: white;;
}
.spin {
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}



.fade-in {
  animation: animation-fade-in 1s;
}

.fade-in-fast {
  animation: animation-fade-in 0.3s;
}

.fade-in-slow {
  animation: animation-fade-in 1.5s;
}

@keyframes animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animation-fade-in-fast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animation-fade-in-slow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



hr {
  margin: 12px 0;
  border: 0;
  text-align: center;
  
}

hr::before{
    content: "\2022 \2022 \2022 \2022 \2022";
    font-size: 25px;
    color: #88B1A7;
    margin: 2em 4em;
}